import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import A from '@styled/Link';
import Human from 'components/MiniHuman';

type ComponentType =
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>['renderInlineRecord'];

const InlineRecord: ComponentType = ({ record: { __typename, ...data } }) => {
	if (__typename === 'HumanRecord') {
		return <Human {...(data as any)} />;
	}
	if (__typename === 'JournalArticleRecord') {
		const { slug, title } = data as any;
		return <A href={`/journal/${slug}`}>{title}</A>;
	}
	if (__typename === 'ComposablePageRecord') {
		const { slug, title } = data as any;
		return <A href={`/${slug}`}>{title}</A>;
	}
	if (__typename === 'JournalIndexRecord') {
		const { title } = data as any;
		return <A href={`/journal`}>{title || 'Journal'}</A>;
	}
	if (__typename === 'HomePageRecord') {
		const { heroSection } = data as any;
		return <A href={`/`}>{heroSection?.[0]?.title || 'Homepage'}</A>;
	}
	return <></>;
};

export default InlineRecord;
