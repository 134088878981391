import type { CSSInterpolation } from '@emotion/serialize';
import React from 'react';
import tw from 'twin.macro';
import { purpleHazeTextGradient } from 'styles';

export type StyledSentenceProps = {
	children: string;
	splitBy?: 'first-words' | 'last-words';
	n?: number;
	twFirstWords?: CSSInterpolation;
	twLastWords?: CSSInterpolation;
} & React.ComponentProps<'span'>;

const Sentence = ({
	children,
	splitBy = 'last-words',
	n = 3,
	twFirstWords = [tw`text-primary`],
	twLastWords = [purpleHazeTextGradient],
	...spanProps
}: StyledSentenceProps) => {
	const wordsOfSentence = children.split(' ').filter((word) => !!word);
	const boundary = n > 0 && n < 1 ? Math.floor(wordsOfSentence.length * n) : n;

	const sliceBoundary =
		splitBy === 'first-words' ? boundary : wordsOfSentence.length - boundary;

	const firstWords = wordsOfSentence.slice(0, sliceBoundary).join(' ');
	const lastWords = wordsOfSentence
		.slice(sliceBoundary, wordsOfSentence.length)
		.join(' ');
	return (
		<>
			{firstWords && (
				<span css={twFirstWords} {...spanProps}>{`${firstWords} `}</span>
			)}
			{lastWords && (
				<span css={twLastWords} {...spanProps}>
					{lastWords}
				</span>
			)}
		</>
	);
};

export default Sentence;
