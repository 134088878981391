import { ascend, sortWith } from 'ramda';
import { Image } from 'react-datocms';
import tw, { css } from 'twin.macro';
import type { HumanShort } from '@datocms/types';
import { socialIconsMap } from '@icons/social';
import { linkFocusRing } from '@styled/Link';
import Heroicon from 'components/Heroicon';
import Link from 'components/SmartLink';

type MiniHumanProps = HumanShort & {
	withAdditions?: boolean;
	size?: 'mini' | 'medium' | 'large';
} & React.ComponentProps<'div'>;

const socialSortOrder = {
	twitter: 0,
	instagram: 1,
	facebook: 2,
	linkedin: 3,
};

const socialSorter = sortWith([
	ascend<[string, string]>(([key]) => socialSortOrder[key]),
]);

const MiniHuman = ({
	avatar,
	name,
	nameAffix,
	nameSuffix,
	withAdditions,
	wikipedia,
	virtualLocation,
	...divProps
}: MiniHumanProps) => {
	const social = virtualLocation?.[0]?.socialMedia?.[0] ?? {};
	const email = virtualLocation?.[0]?.email ?? '';
	const socialArray = socialSorter(
		Object.entries(social).filter(([, value]) => !!value)
	);
	const LinkToBio = wikipedia ? Link : 'div';

	const displayName = withAdditions
		? `${nameAffix} ${name} ${nameSuffix}`
		: name;
	const intitials = name
		// split for spaces and dash
		.split(/[ -]/)
		.map((n) => n[0])
		.join('');
	return (
		<div {...divProps} tw="flex items-center space-x-2.5">
			<LinkToBio
				href={wikipedia}
				tw="flex-shrink-0 h-10 w-10 rounded-full"
				css={[
					css`
						img {
							${tw`(m-0 h-10 w-10 rounded-full)!`}
						}
					`,
					linkFocusRing,
					!avatar &&
						tw`bg-brand-100 inline-flex items-center justify-center ring-1 ring-secondary`,
				]}>
				{avatar && (
					<Image
						tw="rounded-full"
						data={{
							alt: `Avatar of ${displayName}`,
							...avatar.responsiveImage,
						}}
					/>
				)}
				{!avatar && <p tw="text-sm text-brand-800 capitalize">{intitials}</p>}
			</LinkToBio>
			<div tw="flex flex-col space-y-0.5">
				<div tw="inline-block">
					<div tw="text-sm font-medium text-gray-900">{displayName}</div>
				</div>
				<div tw="flex items-center space-x-2.5">
					{email ? (
						<Link css={linkFocusRing} href={`mailto:${email}`}>
							<Heroicon heroicon="mail" tw="h-5 w-5 text-gray-500" />
						</Link>
					) : (
						<></>
					)}
					{socialArray.map(([key, value]) => {
						const Icon = socialIconsMap[key];
						return Icon ? (
							<Link css={linkFocusRing} href={value} key={key}>
								<Icon tw="h-5 w-5 text-gray-500" />
							</Link>
						) : (
							<></>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default MiniHuman;
